@import "~@wtag/react-comp-lib/src/styles/abstracts/variables";
@import "./variables";

@mixin rcl-modal-container-icon-color($color) {
  .rcl-modal__title {
    .icon {
      svg {
        fill: $color;
      }
    }
  }
}

.confirmation-modal {
  .rcl-modal {
    &__container {
      max-width: 30.4rem;
    }

    &__title,
    &__content {
      justify-content: center;
      text-align: center;
    }

    &__content {
      color: $secondary-text;
      margin-top: 0;
    }

    &__title {
      align-items: flex-end;
      font-weight: $boldest-font-weight;

      &,
      .icon {
        margin-bottom: 12px;
      }

      .icon {
        &__content {
          height: 4rem;
          width: 4rem;
        }
      }
    }
  }

  &--success {
    @include rcl-modal-container-icon-color($success);
  }

  &--danger {
    @include rcl-modal-container-icon-color($danger);
  }

  &--neutral {
    @include rcl-modal-container-icon-color($tertiary-accent);
  }

  &--warning {
    @include rcl-modal-container-icon-color($warning);
  }
}
