.modal {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__wrapper {
    .rcl-modal {
      &__content {
        display: block;

        > div {
          margin-bottom: 12px;
        }
      }

      &__footer {
        margin-top: 0;
      }
    }

    &-content {
      .rcl-modal {
        &__content {
          margin-top: 0;
        }
      }
    }

    &-footer {
      &-left {
        .rcl-modal {
          &__footer {
            justify-content: flex-start;
          }
        }
      }
    }

    &-small {
      .rcl-modal {
        &__container {
          width: 350px;
        }
      }
    }

    &-overflow {
      .rcl-modal {
        &__content {
          overflow: visible;
        }
      }

      &-vertical {
        .rcl-modal {
          &__container {
            overflow-y: auto;
          }
        }
      }
    }
  }
}
