.company-logos {
  &__link {
    background: $unavoidable-almond;
    border-radius: 4px;
    color: $white;
    font-size: 12px;
    font-weight: $bold-font-weight;
    padding: 5px 10px;
    text-decoration: none;

    &:hover {
      background: rgba($unavoidable-almond, 0.9);
    }
  }

  &__name {
    color: $exotic-licorice;
    font-size: 14px;
    margin: 10px 0;
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    > * {
      width: 100%;
    }

    .button {
      align-self: flex-end;
      width: auto;
    }
  }

  &__logos {
    margin-bottom: 50px;
  }
}
