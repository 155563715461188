.marketing-card {
  line-height: 1.3;
  padding: 100px 16px;

  &__image {
    margin-bottom: 8px;
  }

  &__title {
    font-size: 24px;
    font-weight: $bold-font-weight;

    @include target-ie {
      text-align: left;
      white-space: normal;
      width: 100%;
    }
  }

  &__text {
    padding: 1rem 0;
    text-align: justify;

    @include target-ie {
      text-align: left;
      white-space: normal;
      width: 100%;
    }
  }

  .button--v2 {
    margin: auto 0 5px 0;
  }
}
