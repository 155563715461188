.brochure-card {
  &__image {
    img {
      height: auto;
      width: 100%;

      @include target-ie {
        height: 100%;
      }
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
  }

  &__text {
    text-align: justify;
    margin: 10px 0;

    @include target-ie {
      text-align: left;
      white-space: normal;
      width: 100%;
    }
  }

  &__button {
    background: $unavoidable-almond;
    border-radius: 4px;
    color: $white;
    font-weight: 500;
    margin: 5px 0;
    padding: 0.5rem 2rem;
    text-decoration: none;

    &:hover {
      background: rgba($unavoidable-almond, 0.9);
    }
  }
}
