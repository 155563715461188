.careers {
  line-height: 1.3;

  section {
    padding: 100px 0;
  }

  &__header,
  &__tasks-header {
    @include align-text(center);
    color: $outdoor-tar;
    font-size: 48px;
    font-weight: $bold-font-weight;
    margin-bottom: 20px;
  }

  &__sub-header,
  &__cto-quote,
  &__cto-name,
  &__tasks-sub-header {
    @include align-text(center);
    color: $outdoor-tar;
    font-size: 20px;
    font-weight: $normal-font-weight;
    line-height: 1.5;
    margin-bottom: 60px;

    span {
      display: block;
    }
  }

  &__company-description-sub-header {
    line-height: 1.5;
  }

  &__company-description-header,
  &__company-description-sub-header,
  &__cto-quote,
  &__cto-name {
    @include align-text(left);
    color: $white;
    margin: 0;

    @media (max-width: $tab-mobile) {
      @include align-text(center);
    }
  }

  &__cto-name {
    @include align-text(right);
    font-weight: $bold-font-weight;
    margin-top: 25px;

    @media (max-width: $tab-mobile) {
      @include align-text(center);
    }
  }

  &__tasks {
    &-header {
      margin-bottom: 0;
    }

    &-sub-header {
      font-weight: $bolder-font-weight;
      margin-bottom: 0;
    }

    &-header,
    &-sub-header {
      text-align: left;
      text-align-last: left;

      @media (max-width: $tab-mobile) {
        text-align: center;
        text-align-last: center;
      }
    }

    &-task-icon {
      height: 135px;
      margin-top: 45px;
      width: auto;
    }

    &-task-name {
      font-size: 36px;
      font-weight: $bold-font-weight;
      margin-top: 30px;
    }

    &-task-description {
      font-size: 20px;
      font-weight: 300;
      line-height: 1.5;
      margin-top: 10px;
    }
  }

  &__map {
    &-header {
      @include text-style(0, $outdoor-tar, center, $bold-font-weight, 20px, 48px);
    }

    &-sub-header {
      @include text-style(0, $outdoor-tar, center, $normal-font-weight, 60px);
    }

    &-img {
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  &__position {
    &-header {
      @include text-style(0, $outdoor-tar, center, $bold-font-weight, 20px, 48px);
    }

    &-sub-header {
      @include text-style(0, $outdoor-tar, center, $normal-font-weight, 60px);
    }

    &-button {
      margin-top: 20px;
    }

    &-open {
      .accordion {
        &__item {
          line-height: 1.5;
        }
      }
    }

    &-section {
      margin-bottom: 30px;
    }
  }

  &__company-description {
    &-header {
      @include text-style(0, $white, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header {
      @include text-style(1px, $white, left, $normal-font-weight, 0);
    }
  }

  &__remote-work {
    &-section {
      overflow: hidden;
      position: relative;

      &-cover {
        height: 100%;
        object-fit: cover;
        object-position: top;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    &-header {
      @include text-style(0, $white, left, $bold-font-weight, 20px, 48px);
    }

    &-benefits {
      @include text-style(1px, $white, left, $bolder-font-weight);
      line-height: 1.5;

      @media (max-width: $tab-mobile) {
        @include align-text(left);
      }
    }
  }

  &__learning-quote {
    &-header {
      @include text-style(0, $white, left, $bold-font-weight, 0, 48px);

      @media (max-width: $tab-mobile) {
        @include align-text(center);
      }
    }

    &-sub-header {
      @include text-style(1px, $white, left, $normal-font-weight, 0);
      line-height: 1.5;

      @media (max-width: $tab-mobile) {
        @include align-text(center);
      }
    }

    &-header,
    &-sub-header {
      @media (max-width: $tab-mobile) {
        @include align-text(center);
      }
    }
  }

  &__cto,
  &__cqso {
    position: relative;

    &-quotation-sign {
      left: -30px;
      position: absolute;
      top: 3px;
      width: 30px;

      @media (max-width: $tab-mobile) {
        align-self: center;
        position: static;
      }
    }

    &-image {
      > img {
        border: 5px solid $white;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba($black, 0.3);
        height: 180px;
        object-fit: cover;
        width: 180px;
      }
    }
  }

  &__cqso {
    &-quote,
    &-name {
      @include text-style(1px, $white, left, $normal-font-weight, 25px);
      line-height: 1.5;
    }

    &-name {
      font-weight: $bold-font-weight;
      margin: 0;

      @media (min-width: 768px) {
        @include align-text(right);
      }
    }
  }

  &__contact,
  &__send-cv {
    &-header {
      @include text-style(0, $outdoor-tar, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header {
      @include text-style(1px, $outdoor-tar, left, $normal-font-weight, 25px);

      button {
        margin-top: 25px;
      }
    }
  }

  &__contact {
    .base-modal__wrapper {
      @include viewport-is(tab-mobile) {
        @include align-text(center);
      }
    }

    .base-modal__header-close {
      display: inline-flex;
      justify-content: flex-end;
    }

    &-sub-header {
      &-button {
        .button {
          width: 100px;
        }
      }
    }
  }

  &__send-cv {
    .text-field__label {
      align-items: center;
      color: $big-stone;
      display: flex;
      font-size: 1rem;
      margin: 0 0 6px;
    }

    .text-field__box {
      background: $white;
      border: 1px solid $periwinkle-gray;
      border-radius: 3px;
      box-shadow: 0 1px 3px rgba($tertiary-accent, 0.16);
      box-sizing: border-box;
      color: $big-stone;
      font-size: 1rem;
      height: 150px;
      padding: 10px 12px;
      resize: none;
      width: 100%;

      &:hover {
        border-color: $polo-blue;
      }

      &:focus {
        border-color: $polo-blue;
        outline: none;
      }

      &::placeholder {
        color: $santas-gray;
        font-size: 1rem;
        font-weight: $normal-font-weight;
      }
    }
  }

  &__apply-button {
    top: 22%;
  }
}
