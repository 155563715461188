.platform-features {
  &__tabs {
    line-height: 1.3;
    margin: 100px 0;

    .rtabs {
      &__tabs {
        &--vertical {
          border-bottom: 0;
          margin-right: 20px;
        }
      }

      &__tab {
        &:first-of-type {
          padding-left: 15px;
        }

        &--selected {
          opacity: 1;
        }

        &--vertical {
          padding-right: 30px;

          &:hover {
            background: $overheated-chicory;
          }
        }
      }

      &__inkbar-wrapper {
        margin-bottom: 30px;
      }

      &__panel {
        padding: 0 1em;
      }

      &__showmore {
        margin-left: 0;

        &-list {
          .rtabs {
            &__tab {
              &:first-of-type {
                padding-left: 7px;
              }

              &--selected {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  &__header {
    color: $primary-text;
    font-size: 42px;
    font-weight: $bold-font-weight;
    margin-bottom: 15px;
    margin-top: 0;
    text-align: left;

    @include viewport-is(mobile) {
      font-size: 24px;
      margin-top: 20px;
    }
  }

  &__sub-header {
    color: $primary-text;
    font-size: 19px;
    font-weight: $bold-font-weight;
    margin-top: 0;
    text-align: left;
  }

  &__text {
    color: $primary-text;
    font-size: 16px;
    text-align: left;
  }

  &__small-text {
    color: $primary-text;
    font-size: 12px;
    text-align: left;
  }

  &__bottom-text {
    color: $primary-text;
    font-size: 16px;
    font-weight: $bold-font-weight;
    text-align: left;
  }

  &__list {
    p {
      margin-bottom: 10px;
    }
    ul {
      list-style: disc;
      margin-top: 0;
    }
  }

  &__button {
    background: $unavoidable-almond;
    border-radius: 4px;
    color: $white;
    font-size: 14px;
    font-weight: $bolder-font-weight;
    padding: 5px 30px;
    text-align: center;

    &:hover {
      background: rgba($unavoidable-almond, 0.9);
    }
  }

  &__apps {
    margin-top: 10px;

    span {
      margin-right: 5px;
    }
  }
}
