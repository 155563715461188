.cropping {
  @at-root #{&}__images {
    display: flex;
    justify-content: space-between;
  }

  @at-root #{&}__image {
    flex-basis: 48%;
  }
}
