.logo-slider {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: flex;
  height: 100px;
  margin-top: 100px;
  max-width: 2000px;
  width: 100%;

  &.five {
    animation-duration: 30s;
  }

  &.four {
    animation-duration: 36s;
  }

  &.three {
    animation-duration: 42s;
  }

  &.two {
    animation-duration: 66s;
  }

  &.one {
    animation-duration: 126s;
  }

  &.right {
    animation-name: rotateRight;
  }

  &.left.five {
    animation-name: rotateLeftFiveItem;
  }

  &.left.four {
    animation-name: rotateLeftFourItem;
  }

  &.left.three {
    animation-name: rotateLeftThreeItem;
  }

  &.left.two {
    animation-name: rotateLeftTwoItem;
  }

  &.left.one {
    animation-name: rotateLeftOneItem;
  }

  &__logo-holder {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 100%;
    justify-content: center;
    max-width: 200px;
  }

  &__logo {
    max-height: 100%;
    max-width: 200px;
  }
}

.logo-container {
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  overflow: hidden;
  width: 100%;
}

@keyframes rotateLeftFiveItem {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-200%);
  }
}

@keyframes rotateLeftFourItem {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-250%);
  }
}

@keyframes rotateLeftThreeItem {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-300%);
  }
}

@keyframes rotateLeftTwoItem {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-500%);
  }
}

@keyframes rotateLeftOneItem {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-1000%);
  }
}

@keyframes rotateRight {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}
