.nav {
  &-list {
    .nav-item {
      &.active {
        color: $cool-gray-99;
        opacity: 1;
        &::after {
          background: $cool-gray-99;
          content: '';
          height: 32px;
          left: 0;
          position: absolute;
          top: 5px;
          width: 2px;
        }
      }
    }
  }

  &-item {
    align-items: center;
    display: flex;
    padding: 10px 25px;
    position: relative;
    user-select: none;

    &__logo {
      left: 50%;
      position: absolute;
      top: 0;
      transform: translate(-75%, -50%);
    }

    &--padding {
      padding: 0 !important; //overriding default padding
      margin-top: 5px;

      &:last-of-type {
        margin-top: 0;
      }
    }
  }

  &-bottom {
    bottom: 0;
    padding-top: 10px;
    position: absolute;
    width: 100%;

    &__settings {
      margin-top: 10px;
    }

    .nav-item {
      color: $cool-gray-99;
      padding-bottom: 5px;
      padding-top: 5px;

      .icon {
        font-size: 22px;
      }

      .text {
        font-size: 12px;
      }
    }
  }
}
