$overheated-chicory: rgba(48, 48, 48, 0.07);
$dark-overheated-chicory: rgba($overheated-chicory, 0.23);
$darker-overheated-chicory: rgba($overheated-chicory, 0.3);
$heated-chicory: rgba(48, 48, 48, 0.05);
$off-chicory: rgba(244, 244, 244, 1);
$off-tar: rgba(14, 33, 27, 0.5);
$black-50: rgba(0, 0, 0, 0.5);
$black-10: rgba(0, 0, 0, 0.1);
$overheated-tar: rgba(14, 33, 27, 0.08);
$ceremonial-cinnamon: #707070;

$white: #ffffff;
$shredded-ivory: #fafafa;
$black: #000000;

$resonant-earth: #3c3c3c;
$unavoidable-almond: #c44a47;
$lawful-jet: rgba(0, 0, 0, 0.16);
$white-jet: rgba(0, 0, 0, 0.02);
$fascinating-jet: rgba(0, 0, 0, 0.08);
$temporary-topaz: #f95d6a;
$daring-lemon: #ff7c43;
$evocative-citron: #ffa602;
$homely-turquoise: #00b575;
$detailed-sapphire: #1a4c6e;
$pleasant-sapphire: #25355e;
$thoughtful-azure: #00385e;
$dusty-oyster: #66ceef;
$lush-sky: #134169;
$multilateral-sapphire: #255178;
$chathams-blue: #11406a;
// Navbar size
$navbar-min-height: 70px;
$navbar-color: $white;
$navbar-shadow: rgba($secondary-accent, 0.16);

$unfocused-cerulean: #1cbbb4;
$analytical-sapphire: #66ceef;
$implicit-steel: #448ccb;
$undercover-sky: #5674b9;
$credible-dove: #605ca8;
$bleary-dove: #8560a8;
$processed-steel: #a864a8;
$trifling-orchid: #f078ae;
$outdoor-tar: #0e214d;
$chathams-blue-light: rgba($chathams-blue, 0.05);
$chathams-blue-aqua: rgba($chathams-blue, 0.75);
$exotic-blue: #f4f7ff;
$lighter-outdoor-tar: rgba($outdoor-tar, 0.08);
$necessary-steel: rgba(14, 33, 77, 0.8);
$high-royal: rgba(29, 98, 255, 0.05);
$called-aqua: #134169;
$speechless-tar: #000029;
$goofy-aqua: #25365e;
$revised-tawny: #e1483d;
$partial-aqua: #2bd8bb;
$blond-turquoise: #4287ef;
$three-day-steel: #10406a;
$delightful-silver: #9881ff;
$hapless-peacock: rgba(58, 73, 109, 0.6);
$exotic-licorice: #000208;
$unbelievable-aqua: #000080;
$sky-blue: rgba(21, 146, 230, 0.07);
$lighter-sky-blue: rgba(21, 146, 230, 0.04);

$despicable-porcelain: #f5fafe;
$ethnic-satin: #daeef9;
$kind-rust: #ec5355;
$official-oyster: #eeeeee;
$disabled: #969aab;
$dynastic-tar: #182146;
$workspace-empty-card-icon-color: #dbdbdb;
$warning-text: #45300b;
