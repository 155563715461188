.sitemap {
  background: $three-day-steel;
  min-height: 100vh;

  .container {
    color: $white;

    @include viewport-is(desktop-tab) {
      padding-bottom: 130px;
    }
  }

  p {
    font-size: 14px;
    font-weight: $bold-font-weight;
    margin-bottom: 20px;
  }

  a {
    color: $white;
    display: block;
    font-size: 14px;
    font-weight: $normal-font-weight;
    margin: 10px 0;
  }

  .about-us__contact-section {
    background: $black-10;
    margin-bottom: 30px;
    padding: 20px 0;

    @include viewport-is(tab-mobile) {
      margin-bottom: 65px;
    }
  }

  &__logo {
    padding-top: 30px;
  }
}
