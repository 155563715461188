html {
  background-color: $white;
  font-size: 16px;
  scroll-behavior: smooth;
}

main {
  position: relative;
}

footer {
  display: block;
}
