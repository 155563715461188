// all of the styles in this file consists of css from react-slick-slider library

@charset 'UTF-8';

/* Arrows */
.slick-prev,
.slick-next {
  border-radius: 0;
  border: none;
  color: transparent;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 30px;
  line-height: 0;
  opacity: 0.1;
  outline: none;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 30px;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  opacity: 0.7;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  -webkit-font-smoothing: antialiased;
  color: $white;
  font-size: 20px;
  left: 50%;
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.slick-prev {
  left: -25px;
}

[dir='rtl'] .slick-prev {
  right: 0;
  left: auto;
}

.slick-next {
  right: -25px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: 0;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: 0;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.slick-dots li {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 20px;
}

.slick-dots li button {
  background: transparent;
  border-radius: 50%;
  border: none;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 20px;
  line-height: 0;
  outline: none;
  padding: 5px;
  width: 20px;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  -webkit-font-smoothing: antialiased;
  color: $cool-gray-92;
  content: '•';
  font-size: 40px;
  height: 20px;
  left: 50%;
  line-height: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}

.slick-dots li.slick-active button:before {
  color: $cool-gray-70;
}

.slick-arrow {
  .icon-button__icon {
    font-size: 20px;
    margin-top: 5px;
  }
}
