.footer {
  background: $secondary-color;
  color: $white;
  left: 0;
  bottom: 0;
  right: 0;

  &__primary {
    padding-top: 30px;

    &-tabs {
      &-tab {
        text-align: right;

        @include viewport-is(desktop-tab) {
          text-align: left;
          padding-bottom: 20px;
        }
      }

      &-tab-title {
        font-size: 14px;
        font-weight: $bolder-font-weight;
        padding-bottom: 20px;

        @include viewport-is(desktop-tab) {
          padding-bottom: 10px;
        }
      }

      &-tab-link {
        display: block;
        color: $white;
        font-size: 12px;
        opacity: 0.65;
        padding-bottom: 8px;
        transition: opacity, 200ms ease-out;

        @include viewport-is(desktop-tab) {
          padding-bottom: 5px;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.9;
        }
      }
    }
  }

  &__secondary {
    align-items: center;
    background: darken($secondary-color, 4);
    display: flex;
    justify-content: space-between;

    &-links {

      @include viewport-is(tab-mobile) {
        text-align: center;
      }

      &-link {
        color: $white;
        display: inline-block;
        font-size: 12px;
        margin-left: 15px;
        margin-right: 15px;
        opacity: 0.65;
        transition: opacity, 200ms ease-out;

        &:first-child {
          color: rgba($white, 0.65);
          opacity: 1;

          &:hover {
            color: $white;
          }
        }

        &:first-of-type {
          margin-left: 0;
        }

        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    &-copyright-text {
      font-size: 12px;
      opacity: 0.65;
      text-align: right;
      margin: auto 0;

      @include viewport-is(tab-mobile) {
        text-align: center;
      }
    }
  }
}
