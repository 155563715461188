.about-us {
  line-height: 1.3;

  section {
    padding: 100px 0;
  }

  &__mission,
  &__query {
    &-header {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header,
    &-details {
      @include text-style(1px, $primary-text, left, $normal-font-weight, 0);
      line-height: 1.5;
    }
  }

  &__quote {
    position: relative;

    &-quotation-sign {
      left: -30px;
      position: absolute;
      top: 3px;
      width: 30px;

      @include viewport-is(tab-mobile) {
        align-self: center;
        position: static;
      }
    }

    &-text,
    &-detail,
    &-speaker-name {
      @include text-style(1px, $white, left, $normal-font-weight, 25px);
    }

    &-image {
      > img {
        border: 5px solid $white;
        border-radius: 3px;
        box-shadow: 0 3px 6px rgba($black, 0.3);
        height: 180px;
        object-fit: cover;
        width: 180px;
      }
    }

    &-speaker-name {
      @include align-text(right);
      font-weight: $bold-font-weight;
      margin: 0;

      @include viewport-is(tab-mobile) {
        @include align-text(center);
      }
    }
  }

  &__principles {
    &-header {
      @include text-style(0, $white, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header,
    &-details {
      @include text-style(1px, $white, left, $normal-font-weight, 0);
      line-height: 1.5;
    }

    &-principle {
      margin-bottom: 50px;

      &-title {
        @include text-style(0, $white, left, $bold-font-weight, 0);
      }

      &-description {
        @include text-style(1px, $white, left, $normal-font-weight, 0, 20px);
        line-height: 1.5;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__timeline {
    &-header {
      @include text-style(0, $white, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header {
      @include text-style(1px, $white, left, $normal-font-weight, 0);
      line-height: 1.5;
    }

    img {
      margin-top: auto;
    }
  }

  &__team {
    &-header {
      @include text-style(0, $primary-text, center, $bold-font-weight, 0, 48px);
    }

    &-sub-header {
      @include text-style(
        1px,
        $primary-text,
        center,
        $normal-font-weight,
        40px
      );
    }

    &-member {
      > * {
        display: flex;
        justify-content: center;
        width: 100%;
      }

      &-image {
        > img {
          border-radius: 3px;
          border: 5px solid $white;
          box-shadow: 0 3px 6px rgba($black, 0.3);
          height: 180px;
          object-fit: cover;
          width: 180px;
        }
      }

      &-name {
        @include text-style(0, $primary-text, center, $bold-font-weight, 0);
        margin-top: 15px;
      }

      &-designation {
        @include text-style(0, $primary-text, center, $normal-font-weight, 0);
      }
    }
  }

  &__contact {
    align-items: center;
    display: flex;
    height: 60vh;
    position: relative;

    &-banner {
      height: 100%;
      overflow-x: hidden;
      width: 100vw;

      img {
        height: 100%;
        margin-bottom: -5px;
        overflow: hidden;
      }
    }

    &-section {
      background: $hapless-peacock;
      bottom: 0;
      left: 0;
      padding: 100px 0;
      position: absolute;
      width: 100%;

      &-icon {
        align-items: flex-end;
        display: flex;

        img {
          height: 100px;
        }
      }
    }

    &-header {
      color: $white;
      font-size: 24px;
      font-weight: $boldest-font-weight;
      margin-bottom: 5px;
    }

    &-mail {
      color: $white;

      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}
