.plan-card {
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba($tertiary-accent, 0.16);
  box-sizing: border-box;
  color: $primary-text;
  height: 100%;
  padding: 32px 24px 24px;
  position: relative;

  &__title {
    font-size: 16px;
    font-weight: $bold-font-weight;
    margin: 0 0 12px;
    text-align: center;
  }

  &__price {
    color: $tertiary-accent;
    font-size: 32px;
    font-weight: $bold-font-weight;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;
  }

  &__subscription-period {
    color: rgba($primary-text, 0.7);
    font-size: 14px;
    margin: 8px 0 0;
    text-align: center;
    text-transform: lowercase;
  }

  &__divider {
    background-color: rgba($tertiary-accent, 0.16);
    height: 1px;
    margin: 38px 0 50px;
  }

  &__feature-list {
    margin: 0;
    padding: 0;

    &-item {
      display: flex;
      font-size: 14px;
      justify-content: space-between;

      &:not(&:last-child) {
        margin: 0 0 24px;
      }

      &-value {
        font-weight: $bold-font-weight;
      }
    }
  }

  &__button {
    margin: 82px 0 0;
    width: 100%;
  }

  &--active {
    border-top-left-radius: 0;
    box-shadow: 0 0 0 2px $tertiary-accent;
    transition: box-shadow 0.2s ease;

    &::before {
      background-color: $tertiary-accent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      color: $white;
      content: attr(data-selected);
      font-size: 12px;
      left: 0;
      padding: 5px 12px;
      position: absolute;
      top: 0;
      transform: translate(-2px, -100%);
      transition: transform 0.2s ease;
      z-index: -1;
    }
  }

  &--disabled {
    opacity: 0.8;
    pointer-events: none;
  }
}
