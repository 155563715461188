@mixin align-text($position) {
  text-align: $position;
  text-align-last: $position;
}

@mixin text-style(
  $letter-spacing: 0,
  $color: $white,
  $text-align: left,
  $font-weight: 300,
  $margin-bottom: 20px,
  $font-size: 20px
) {
  @include align-text($text-align);
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
  margin-bottom: $margin-bottom;
  letter-spacing: $letter-spacing;

  span {
    display: block;
  }

  @media (max-width: $tab-mobile) {
    @include align-text(center);
  }
}

.full-width {
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
}

.primary-bg {
  background-color: $primary-accent;
  color: $white;
}

.secondary-bg {
  background-color: $secondary-accent;
  color: $white;
}

.tertiary-light-bg {
  background-color: $high-royal;
}

.secondary-overlay {
  background-color: rgba($secondary-accent, 0.75);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
