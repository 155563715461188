.side-drawer {
  align-content: flex-start;
  align-items: flex-start;
  background: $shredded-ivory;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: space-between;
  overflow-y: auto;
  position: absolute;
  right: -100%;
  top: 0;
  transition: right 0.8s ease-out;
  width: 100%;

  &__menu {
    width: 100%;

    @include viewport-is(mobile) {
      width: 98%;
    }
  }

  @include viewport-is-greater(tab-mobile) {
    display: none;
  }

  &__sign-options {
    text-align: center;

    @include viewport-is(mobile) {
      width: 90vw;
    }
  }

  &.open {
    right: 0;

    .side-drawer__sign-options .btn {
      width: 100px;
    }
  }

  &__menu-items {
    padding-left: 8px;

    @include viewport-is(desktop-tab) {
      padding-right: 8px;
    }

    &--side-menu {
      border-bottom: 1px solid $cool-gray-97;
      margin-bottom: 10px;
    }
  }

  &__menu-item {
    &--link {
      background: $fascinating-jet;
      border-radius: 3px;

      span {
        color: $primary-text;
        font-size: 14px;
        font-weight: 500;
      }
    }

    &--label {
      span {
        color: $primary-text;
        font-size: 14px;
        font-weight: 400;
      }
    }

    a {
      display: inline-block;
      padding: 8px;
      width: 97%;

      span {
        color: $primary-text;
        font-size: 14px;
        font-weight: 400;

        &:active,
        &:hover,
        &:focus {
          font-weight: 500;
        }
      }
    }
    &-arrow {
      cursor: pointer;
      float: right;
      margin-top: 5px;

      &--open {
        float: right;
        margin-top: 5px;
      }
    }

    &--my-wrokspace {
      .side-drawer {
        &__sub-menu-items {
          max-height: 0;
          overflow: auto;
          transition: all 0.5s ease-out;

          &--open {
            max-height: 1000px;
            padding-left: 20px;
          }
        }
      }
    }
  }

  &__sub-menu-items {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease-out;

    &--open {
      max-height: 180px;
      padding-left: 20px;
    }
  }

  &__sub-menu-item {
    padding-left: 1.3rem;

    &:last-child {
      border-bottom: none;
    }
  }

  &__sign-options {
    bottom: 0;
    left: 0;
    padding: 16px;
    position: absolute;
    right: 0;

    .navbar__registration {
      display: flex;
    }
  }
}
