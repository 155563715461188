.sign-up-icon {
  width: 300px;

  &__icon {
    text-align: center;
  }

  &__text {
    color: $white;
    font-size: 48px;
    margin-bottom: 12px;
    text-align: center;
  }
}
