.policy {
  &__text {
    letter-spacing: 1px;
    line-height: 1.5;
    margin-bottom: 30px;
    text-align: justify;

    &-list {
      list-style-type: disc;
    }
  }
}
