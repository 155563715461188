// all of the styles in this file consists of css from react-slick-slider library

/* Slider */
.slick-slider {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  box-sizing: border-box;
  display: block;
  position: relative;
  touch-action: pan-y;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 0;
}

.slick-track:before,
.slick-track:after {
  content: '';
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  text-align: center;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-image {
  height: 200px;
  width: 200px;
}

.slick-swiss {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
}
