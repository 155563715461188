.homepage {
  animation: header 1.2s;
  background: $white;
  padding-bottom: 70px;
  overflow-x: hidden;
  line-height: 1.3;

  section {
    padding: 100px 0;

    &.homepage__clients {
      padding: 50px 0;
    }
  }

  &__heroimage {
    background: transparent
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.01) 0%,
        rgba(29, 98, 255, 0.05) 100%
      )
      0% 0% no-repeat padding-box;
    padding: 50px 0;

    .button--v2 {
      border: 0;
      box-sizing: border-box;
      height: 32px;
    }

    .button--primary {
      margin-right: 8px;
    }

    &-header {
      color: $primary-text;
      font-size: 56px;
      font-weight: $boldest-font-weight;
      margin-bottom: 30px;
      margin-top: 30px;

      @include viewport-is(desktop-tab) {
        font-size: 52px;
      }

      @include viewport-is(tab-mobile) {
        font-size: 48px;
      }

      @include viewport-is(mobile) {
        font-size: 36px;
        margin-top: 0;
      }
    }

    &-sub-header {
      color: $necessary-steel;
      font-size: 25px;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    &-sales-text {
      color: $necessary-steel;
      font-size: 16px;
      margin-bottom: 30px;
      margin-top: 30px;
    }

    &-button {
      align-items: center;
      background: $unavoidable-almond;
      border-radius: 3px;
      color: $white;
      cursor: pointer;
      display: inline-flex;
      font-size: 16px;
      font-weight: $bolder-font-weight;
      height: 32px;
      margin-right: 10px;
      margin-top: 10px;
      padding: 0 16px;
      justify-content: center;
      line-height: 1.5em;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        background: rgba($unavoidable-almond, 0.9);
      }
    }

    &-show-more {
      background: $lawful-jet;
      border-radius: 3px;
      border: none;
      color: $black;
      cursor: pointer;
      display: inline-flex;
      font-size: 16px;
      font-weight: $bolder-font-weight;
      height: 32px;
      justify-content: center;
      line-height: 1.5em;
      margin-top: 10px;
      padding: 3px 16px;
      text-align: center;
      text-decoration: none;
      white-space: nowrap;
    }

    &-section {
      padding-left: 20px;

      &-image {
        position: absolute;

        &--travelapp {
          animation: header 1200ms;
          transform: translateX(25%);

          @include viewport-is(large-desktop) {
            transform: scale(1.2) translateX(35%);
          }

          @include viewport-is(desktop-tab) {
            transform: scale(1.2) translateX(40%);
          }
        }

        &--mobileapp {
          animation: partnerlogos 1200ms;
          transform: scale(1.25) translateX(65%);

          @include viewport-is(large-desktop) {
            top: 30%;
            transform: scale(1.25) translateX(45%);
          }

          @include viewport-is(desktop-tab) {
            display: none;
          }
        }
      }
    }
  }

  &__partnership {
    animation: partnerlogos 2s;
    background: $high-royal;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-top: 30px;
    padding: 10px;

    &-text {
      color: $primary-text;
      font-size: 20px;
      font-weight: $bold-font-weight;
      margin: 10px;
    }

    &-logos {
      align-items: center;
      display: flex;
      flex-flow: row wrap;

      @include viewport-is(mobile) {
        display: block;
      }

      &--amadeus {
        height: 18px;
      }

      &--sabre {
        height: 18px;
      }

      &--iata {
        height: 32px;
      }

      &--atpco {
        height: 30px;
      }

      &--travelport {
        height: 24px;
      }

      > div {
        margin: 10px;
      }
    }
  }

  &__clients {
    position: relative;

    &-quotation-sign {
      left: -30px;
      position: absolute;
      top: 3px;
      width: 30px;

      @include viewport-is(desktop-tab) {
        align-self: center;
        position: static;
      }
    }

    &-name {
      @include text-style(1px, $white, left, $bold-font-weight, 0);

      @include viewport-is(desktop-tab) {
        @include align-text(center);
      }

      @media (min-width: $desktop-tab) {
        @include align-text(right);
      }
    }

    &-quote {
      @include text-style(1px, $white, left, $normal-font-weight, 25px);

      @include viewport-is(desktop-tab) {
        @include align-text(center);
      }
    }

    &-image {
      @media (min-width: $desktop-tab) {
        align-items: flex-start;
      }

      > img {
        border: 5px solid $white;
        border-radius: 5px;
        box-shadow: 0 3px 6px rgba($black, 0.3);
        height: 180px;
        object-fit: cover;
        width: 180px;
      }
    }
  }

  &__airelines {
    &-header {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 48px);

      @include viewport-is(desktop-tab) {
        @include align-text(center);
      }
    }

    &-sub-header {
      @include text-style(0, $primary-text, left, $bolder-font-weight, 0);

      @include viewport-is(desktop-tab) {
        @include align-text(center);
      }
    }

    &-img {
      max-height: 100px;
    }
  }

  &__platform {
    &-section {
      margin: 100px 0;

      &-header {
        @include text-style(
          0,
          $primary-text,
          left,
          $bold-font-weight,
          40px,
          48px
        );
        align-items: center;
        display: flex;
        justify-content: space-between;

        @include viewport-is(tab-mobile) {
          font-size: 24px;
          margin-bottom: 10px;
        }

        a {
          height: 32px;
        }
      }

      &-contact {
        align-items: center;
        display: flex;
        margin: 70px 0 0;

        &-header {
          @include text-style(
            0,
            $primary-text,
            left,
            $bold-font-weight,
            0,
            48px
          );
          align-items: center;
          display: flex;
          margin-right: 20px;

          @include viewport-is(tab-mobile) {
            font-size: 24px;
            margin-bottom: 10px;
          }

          a {
            height: 32px;
          }
        }
      }

      &-button {
        background: $overheated-chicory;
        border-radius: 4px;
        color: $black;
        cursor: pointer;
        font-size: 16px;
        font-weight: $bold-font-weight;
        height: auto;
        padding: 6px 20px;
        white-space: nowrap;
        width: auto;

        @include viewport-is(tab-mobile) {
          font-size: 12px;
          margin-left: 5px;
          padding: 5px;
        }
      }

      &-sign-button {
        background: $unavoidable-almond;
        border-radius: 4px;
        color: $white;
        cursor: pointer;
        font-size: 16px;
        font-weight: $bold-font-weight;
        height: auto;
        padding: 6px 20px;
        white-space: nowrap;
        width: auto;

        @include viewport-is(tab-mobile) {
          font-size: 12px;
          padding: 5px 20px;
        }

        @include viewport-is(mobile) {
          position: relative;
        }

        &:hover {
          background: rgba($unavoidable-almond, 0.9);
        }
      }

      &-text {
        color: $primary-text;
        font-size: 24px;
        font-weight: $boldest-font-weight;
        margin-left: 20px;

        @include viewport-is(tab-mobile) {
          font-size: 16px;
          margin-left: 0;
        }
      }

      &-takeoff-img {
        margin-right: 30px;

        @include viewport-is(tab-mobile) {
          display: none;
        }
      }
    }

    &-feature-card {
      border: 1px solid rgba($tertiary-accent, 0.16);
      border-radius: 3px;
      cursor: pointer;
      padding: 30px;
      transition: box-shadow 0.2s linear;

      &:hover {
        box-shadow: 0 0 20px 5px rgba($tertiary-accent, 0.16);
      }

      &-name {
        @include text-style(
          0,
          $primary-text,
          left,
          $bolder-font-weight,
          0,
          20px
        );
        margin-left: 10px;

        @include viewport-is(tab-mobile) {
          @include align-text(left);
        }

        > span {
          display: inline-block;
        }
      }

      &-icon {
        vertical-align: sub;
      }

      &-description {
        @include text-style(
          1px,
          $primary-text,
          left,
          $normal-font-weight,
          0,
          16px
        );
        line-height: 1.5;
        margin: 15px 0;

        @include viewport-is(tab-mobile) {
          @include align-text(left);
        }
      }

      &-link {
        margin-top: auto;

        > a {
          @include text-style(
            1px,
            $primary-text,
            left,
            $bolder-font-weight,
            0,
            16px
          );
          color: rgba($primary-accent, 0.7);
          text-decoration: underline;
          transition: color 0.2s linear;

          @include viewport-is(tab-mobile) {
            @include align-text(left);
          }

          > span {
            display: inline;
          }

          &:hover {
            color: $primary-accent;
          }
        }
      }
    }
  }

  &__service {
    background: $high-royal;
    padding: 50px 0;
    position: relative;

    &-header {
      color: $primary-text;
      font-size: 48px;
      font-weight: $boldest-font-weight;
      margin-bottom: 0;
      margin-top: 0;

      @include viewport-is(tab-mobile) {
        font-size: 36px;
      }

      @include viewport-is(mobile) {
        font-size: 30px;
      }
    }

    &-img {
      display: flex;
      margin: auto;
      margin-right: 50px;
    }

    &-text {
      color: $necessary-steel;
      font-size: 16px;
      line-height: 1.5;
    }

    &-btn-group {
      display: flex;

      @include viewport-is(mobile) {
        display: block;
      }
    }

    .button--v2 {
      box-sizing: border-box;
      height: 32px;
      margin-right: 4px;
    }

    &-social-button {
      margin-right: 4px;

      img {
        border-radius: 3px;
        height: 32px;
        vertical-align: middle;
        width: auto;
      }

      &:hover {
        box-shadow: 0 3px 10px rgba(75, 115, 167, 0.24);
      }
    }
  }

  &__customer-section {
    padding: 100px 0;

    &-header {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header {
      @include text-style(1px, $primary-text, left, $bolder-font-weight, 30px);
    }

    &-active-user-card {
      align-items: center;
      background-color: rgba($success, 0.1);
      border-radius: 5px;
      display: inline-flex;
      margin-bottom: 40px;
      padding: 25px 30px;

      &-number,
      &-text {
        display: inline-block;
        line-height: 1;
      }

      &-number {
        @include text-style(0, $success, left, $bold-font-weight, 0, 36px);
      }

      &-text {
        @include text-style(0, $primary-text, left, $bold-font-weight, 0, 14px);
        margin-left: 20px;
      }

      &-number,
      &-text {
        @include viewport-is(desktop-tab) {
          @include align-text(left);
        }
      }
    }

    &-text {
      @include text-style(
        0,
        $primary-text,
        left,
        $bolder-font-weight,
        30px,
        20px
      );
      line-height: 1.5;

      &--small {
        font-size: 16px;
        margin-bottom: 20px;
      }

      &--fade {
        color: rgba($primary-text, 0.7);
      }
    }

    &-header,
    &-sub-header,
    &-text {
      @include viewport-is(desktop-tab) {
        @include align-text(left);
      }
    }

    &-operator,
    &-clients {
      &-header {
        @include text-style(0, $primary-text, left, $bold-font-weight, 0, 14px);

        @include viewport-is(desktop-tab) {
          @include align-text(left);
        }
      }

      &-company {
        align-items: center;
        display: flex;

        &-name {
          @include text-style(
            0,
            $primary-text,
            left,
            $bold-font-weight,
            0,
            14px
          );
        }
      }
    }
  }
}

// media queries for homepage container

@media only screen and (min-width: 1200px) {
  .homepage .container,
  .footer .container {
    max-width: 1550px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .homepage.container,
  .footer .container {
    max-width: 850px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 990px) {
  .homepage .container,
  .footer .container {
    max-width: 670px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 766px) {
  .homepage .container,
  .footer .container {
    max-width: 480px;
  }
}

@media only screen and (min-width: 360px) and (max-width: 479px) {
  .homepage .container,
  .footer .container {
    max-width: 360px;
  }
}

@keyframes header {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes partnerlogos {
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
