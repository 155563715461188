.change-plans {
  color: $primary-text;
  padding: 40px;

  &__page-title {
    font-size: 24px;
    font-weight: $bold-font-weight;
    margin: 0 0 8px;
  }

  &__plan-unvailability-alert {
    background-color: rgba($warning, 0.16);
    padding: 16px;

    .rcl-alert {
      &__content {
        width: 100%;
      }
    }

    &-content {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-button {
        margin: 4px 0 !important; // Using importand to prevent a global override .content-private .button { margin-right: 8px; } in _layout.scss
      }
    }
  }

  &__select-subscription-mode {
    align-items: center;
    display: flex;

    &-switch {
      margin-right: 12px;
    }

    &-helper-text {
      font-size: 14px;

      strong {
        font-weight: $bold-font-weight;
      }
    }
  }

  &__packages {
    margin: 24px 0 0;

    &-item {
      margin: 0 0 20px;
    }
  }
}
