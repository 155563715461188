.navbar {
  align-items: center;
  background: $navbar-color;
  box-shadow: 0 2px 3px $navbar-shadow;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  position: fixed;
  top: 0;
  transition: height, box-shadow, 300ms ease-in-out;
  width: 100%;
  z-index: 20;

  .side-drawer {
    transition: top 300ms ease-in-out, right 0.8s ease-out,
      height 300ms ease-in-out;
  }

  &--small {
    height: 50px;
    box-shadow: 0 6px 6px $navbar-shadow;

    .side-drawer {
      height: calc(100vh - 50px);
      top: 50px;
    }
  }

  &--normal {
    height: 70px;

    .side-drawer {
      height: calc(100vh - 70px);
      top: 70px;
    }
  }

  &__workspaces {
    &-content {
      margin: 0;
      padding: 0;
    }

    .navbar__sub-menu-link {
      align-items: center;
      display: flex;
      justify-content: flex-start;
    }
  }

  &__user-area {
    .navbar__tooltip-text {
      right: 15px;
    }

    .popover--bottom-left {
      top: 90%;
    }
  }

  &__user-settings {
    &-menu {
      align-items: stretch;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      min-width: 180px;
      padding: 10px;
    }

    &-user-info {
      align-self: center;
      font-size: 14px;
      text-align: center;
      padding: 10px 0;
    }

    &-iconLabel {
      color: $default;
    }

    &-avatar {
      align-self: center;
      position: relative;

      .icon-button {
        position: absolute;
        transform: translate(0px, -10px);

        &:hover {
          background: $fascinating-jet;
        }

        &__label {
          background-color: $white;
          color: $cool-gray-20;
          font-size: 14px;
          height: auto;
          padding: 2px 10px;
          transform: translateX(-70px);
        }
      }
    }

    &-policy-links {
      display: inline-flex;
      font-size: 12px;
      justify-content: space-between;
      margin: 10px 0 0;

      @include target-ie {
        display: block;
      }

      a {
        @include target-ie {
          display: block;
          margin-bottom: 10px;
        }
      }
    }

    &-actions {
      align-self: center;
      margin: 10px 0;

      a {
        padding: 3px 15px;
      }
    }

    &-button {
      background: $cool-gray-20-12;
      border-radius: 3px;
      color: $cool-gray-20;
      font-size: 14px;
      font-weight: 500;
      padding: 0.3rem 1rem;
      text-decoration: none;
    }

    .popover {
      padding: 20px 20px 10px;
      width: auto;

      &-wrapper {
        vertical-align: middle;
      }

      &__link-label {
        padding: 0;
      }

      &--bottom-right {
        right: 0;
      }
    }
  }

  &__my-workspace {
    .navbar {
      &__sub-menu-link {
        align-items: center;
        display: flex;
      }

      &__workspaces-content {
        padding: 0;
      }
    }
  }

  &__tooltip {
    position: relative;
    display: inline-block;

    &-text {
      background: $white;
      border-radius: 4px;
      @include shadow($cool-gray-60, 0, 0, 3px, 6px);
      color: $cool-gray-20;
      font-size: 12px;
      font-weight: $bold-font-weight;
      height: auto;
      opacity: 1;
      padding: 4px;
      position: absolute;
      right: 5px;
      text-align: center;
      transform: translateY(30px);
      visibility: hidden;
      width: auto;
      z-index: 1;
    }

    &:hover .navbar__tooltip-text {
      visibility: visible;
    }
  }

  .popover {
    border-radius: 3px;
    border: 1px solid $overheated-chicory;
    @include shadow($lawful-jet, 0, 0, 3px, 24px);
    text-align: left;

    .navbar__sub-menu-item {
      &:hover {
        background: $fascinating-jet;
      }
    }

    ul {
      padding: 0 2px;
    }

    &__state {
      display: none;
    }
  }

  &__registration {
    a {
      margin: 4px;
      padding: 3px 16px;
    }
  }

  &__right {
    align-items: stretch;
    display: flex;
    margin-right: 5px;

    @media only screen and (max-width: 1200px) {
      padding-left: 0;
    }

    @include viewport-is(mobile) {
      margin-right: 0;

      .popover {
        &__link {
          &-label {
            padding: 0;
          }
        }
      }
    }

    &-item {
      &-language,
      &-workspaces {
        display: inline-flex;
      }

      &-workspaces {
        .popover {
          top: 97%;
        }
      }

      .navbar__sub-menu-item {
        padding: 6px;
      }

      .navbar__sub-menu-link {
        align-items: center;
        display: flex;
        margin-left: 0;
      }

      &:hover {
        .popover-wrapper {
          background-color: rgba(60, 64, 67, 0.08);
          border-radius: 4px;
          opacity: 1;
          outline: none;

          &:last-of-type {
            background-color: transparent;
          }
        }
      }
    }
  }

  &__language-settings {
    cursor: pointer;
    margin: 0;
    padding: 0;

    .navbar__sub-menu {
      left: auto;
      right: 0;
      top: 25px;
    }

    @include viewport-is(desktop-tab) {
      margin-top: 0;
    }
  }

  &__logo {
    margin-left: 1rem;
    transition: height, width, 300ms ease-in-out;

    &--normal {
      height: 70px;
      width: 200px;

      @include viewport-is(desktop-tab) {
        top: 35px;
        width: 175px;
      }

      @include viewport-is(mobile) {
        top: 35px;
      }

      img {
        max-height: 70px;
      }
    }

    &--small {
      height: 50px;
      width: 150px;

      @include viewport-is(desktop-tab) {
        top: 25px;
        width: 150px;
      }

      @include viewport-is(mobile) {
        top: 25px;
        width: 130px;
      }

      img {
        max-height: 50px;
      }
    }

    &--hide {
      @include viewport-is-greater(tab-mobile) {
        display: none;
      }
    }

    &--private {
      visibility: hidden;

      @include viewport-is(tab-mobile) {
        visibility: visible;
      }
    }

    @include viewport-is(tab-mobile) {
      left: 50%;
      margin-left: 0;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    img {
      height: auto;
      max-width: 100%;
      padding: 3px;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      @include viewport-is(mobile) {
        transform: translate(-10%, -50%);
      }
    }
  }

  &__menu {
    align-self: stretch;
    display: flex;
    margin: 0;

    @include viewport-is(desktop-tab) {
      padding-left: 0;
    }

    .navbar__sub-menu {
      background: $white;
      border-radius: 3px;
      border: 1px solid $overheated-chicory;
      @include shadow($lawful-jet, 0, 0, 3px, 24px);
      opacity: 1;
      padding: 0 2px;

      &-item {
        padding: 6px;
      }
    }

    @include viewport-is(tab-mobile) {
      display: none;
    }

    &-link {
      color: $cool-gray-35;
      font-weight: $bold-font-weight;
      transition: font-size, 300ms ease-in-out;

      &--normal {
        font-size: 16px;
      }

      &--small {
        font-size: 14px;
      }

      @include viewport-is(desktop-tab) {
        font-size: 14px;
      }

      &:hover {
        color: $cool-gray-35;
      }
    }
  }

  &__menu-item {
    align-self: center;
    cursor: pointer;
    position: relative;
    margin: -1px 20px 0 0;
    display: flex;
    align-items: center;
    height: 100%;

    &-locale-wrapper {
      padding: 5px;

      .menu__list-item {
        list-style: none;
      }

      .rcl-popover__content {
        padding: 0;
      }
    }

    &-language-wrapper {
      padding: 5px;

      .rcl-menu__list-item {
        list-style: none;
      }

      .rcl-popover__content {
        padding: 0;
      }
    }

    @include viewport-is(desktop-tab) {
      margin-right: 5px;
    }

    .navbar__right > &:last-child {
      margin: -1px 10px 0 0;
    }

    &:hover {
      .navbar__sub-menu {
        display: block;
      }
    }
  }

  &__menu-item-arrow {
    cursor: pointer;
    height: 6px;
    margin-left: 5px;
    width: 10px;
  }

  &__sub-menu {
    border-radius: 4px;
    position: absolute;
    background: $white;
    left: -50%;
    min-width: 160px;
    display: none;
    @include shadow($cool-gray-70, 0, 0, 3px, 6px);

    &--small {
      top: 40px;
    }

    &--normal {
      top: 50px;
    }

    &--language {
      &-name {
        color: $black;
        font-size: 14px;
        font-weight: $bolder-font-weight;
        white-space: pre-wrap;
      }
    }

    &--workspaces {
      &-code {
        color: $white;
        font-size: 12px;
        font-weight: $boldest-font-weight;
        margin-left: 10px;
        margin-right: 5px;
        background: $dusty-oyster;
        border-radius: 4px;
        opacity: 1;
        padding: 4px;
      }

      &-spinner {
        text-align: center;
      }

      &-name {
        color: $black;
        font-size: 14px;
        font-weight: $bolder-font-weight;
      }
    }

    &-link {
      color: $black;
      display: block;
      font-weight: $bolder-font-weight;
      width: 100%;

      &--small {
        font-size: 12px;
      }

      &--normal {
        font-size: 14px;

        @include viewport-is(desktop-tab) {
          font-size: 12px;
        }
      }

      span {
        margin-left: 6px;
      }

      &:hover {
        color: $cool-gray-35;
      }
    }
  }

  &__sub-menu-item {
    padding: 6px;

    &:last-child {
      margin-bottom: 2px;
    }

    &:first-child {
      margin-top: 2px;
    }

    &:hover {
      background: $fascinating-jet;
      border-radius: 3px;
    }
  }

  &__sign-options {
    @include viewport-is(tab-mobile) {
      display: none;
    }
  }

  &__menu-icon {
    cursor: pointer;
    display: none;
    margin-left: 10px;
    margin-right: 10px;

    @include viewport-is(tab-mobile) {
      display: block;
    }
  }

  &__coming-soon-tag {
    margin-left: 10px;
  }
}
