.notification {
  display: flex;
  justify-content: flex-end;
  padding-left: 10px;

  &__box {
    background: $kind-rust;
    border-radius: 10px;
    padding: 2px 10px;
  }

  &__dot {
    background: $kind-rust;
    border-radius: 50%;
    height: 10px;
    position: absolute;
    top: 0;
    transform: translate(18px, 5px);
    width: 10px;
  }
}
