.support-section {
  align-items: stretch;
  background-color: $white;
  display: flex;
  flex-flow: row nowrap;
  height: 200px;
  justify-content: center;
  margin: 100px 0;
  width: 100%;

  @include viewport-is(mobile) {
    flex-flow: column nowrap;
    height: auto;
  }

  &__logo,
  &__info {
    padding: 0 25px;

    @include viewport-is(tab-mobile) {
      padding: 25px;
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    @include viewport-is(tab-mobile) {
      justify-content: center;
    }
  }

  &__info {
    align-items: flex-start;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  &__title {
    @include text-style(0, $primary-text, left, $bold-font-weight, 0);

    @include viewport-is(tab-mobile) {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 20px);
    }
  }

  &__details {
    @include text-style(1px, $primary-text, left, $normal-font-weight);
    line-height: 1.5;

    @include viewport-is(tab-mobile) {
      @include text-style(
        1px,
        $primary-text,
        left,
        $normal-font-weight,
        20px,
        20px
      );
    };
  }
}
