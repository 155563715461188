.media {
  &__wrapper {
    line-height: 1.3;

    .support-section__actions {
      @media (max-width: $tab-mobile) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__serve-section {
    .media__overview-header {
      font-size: 24px;
      padding-bottom: 5px;
    }
  }

  &__contact-us-section {
    background: $high-royal;
    color: $primary-text;
    padding: 100px 0;

    @include viewport-is(tab-mobile) {
      padding: 20px 0;
    }
  }

  &__header {
    @include text-style(0, $primary-text, left, $bold-font-weight, 0, 48px);

    @include viewport-is(tab-mobile) {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 30px);
    }
  }

  &__sub-header {
    @include text-style(0, $primary-text, left, $bold-font-weight, 0);
    line-height: 1.5;

    @include viewport-is(tab-mobile) {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 20px);
    }
  }

  &__description {
    @include text-style(1px, $primary-text, left, $normal-font-weight);
    line-height: 1.5;

    @media (max-width: $tab-mobile) {
      span {
        display: inline !important; //overriding inline helper css
      }
    }

    &-contact {
      span {
        display: inline;
      }
    }

    @include viewport-is(tab-mobile) {
      @include text-style(
        1px,
        $primary-text,
        left,
        $normal-font-weight,
        20px,
        18px
      );
    }

    &-link {
      color: $primary-text;
      text-decoration: underline;
    }

    &-anchor {
      color: $white;
      text-decoration: underline;
    }
  }

  &__company-overview-section {
    background-image: url('../../../assets/images/overview.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    position: relative;

    .media__overview-header {
      font-size: 24px;
      padding-bottom: 5px;
    }

    &-bg {
      background-color: rgba($secondary-accent, 0.75);
      padding: 100px 0;

      @include viewport-is(tab-mobile) {
        padding: 20px 0;
      }
    }

    .media__header {
      @include text-style(0, $white, left, $bold-font-weight, 50px, 48px);
    }
  }

  &__founder-story-section {
    color: $primary-text;
    padding: 100px 0;

    @include viewport-is(tab-mobile) {
      padding: 20px 0;
    }

    .media__sub-header {
      margin-top: 20px;
    }
  }

  &__brochure-button {
    margin-top: 30px;

    @media (max-width: $tab-mobile) {
      display: flex;
      justify-content: center;
    }
  }

  &__logos-section {
    color: $primary-text;
    padding: 100px 0;

    @include viewport-is(tab-mobile) {
      padding: 20px 0;
    }

    .media__header {
      margin-bottom: 20px;
    }
  }

  &__company-story-section {
    background-image: url('../../../assets/images/plane.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    position: relative;

    .media__header {
      color: $white;
      margin-bottom: 20px;
    }

    .media__sub-header {
      color: $white;
    }

    &-bg {
      padding: 100px 0;

      @include viewport-is(tab-mobile) {
        padding: 20px 0;
      }
    }
  }

  &__story-description {
    margin-top: 30px;
  }

  &__image {
    margin-top: 50px;

    @include viewport-is(tab-mobile) {
      margin-top: 30px;
    }
  }

  &__company-details-section {
    background: $secondary-accent;
    color: $white;
    padding: 100px 0;

    .media__overview-header {
      font-size: 24px;
      padding-bottom: 5px;
    }

    @include viewport-is(tab-mobile) {
      padding: 20px 0;
    }

    .media__header {
      color: $white;
      margin-bottom: 50px;
    }
  }

  &__serve-section {
    .media__header {
      margin-bottom: 50px;
    }

    .media__overview-description {
      border-top: 1px solid $primary-text;
      color: $primary-text;
    }

    .media__overview-header {
      color: $primary-text;
    }
  }

  &__get-epk-section {
    background-color: $unavoidable-almond;
    padding: 100px 0;

    @include viewport-is(tab-mobile) {
      padding: 20px 0;
    }

    .media__header {
      color: $white;
    }

    .media__sub-header {
      color: $white;
      font-weight: $normal-font-weight;
    }

    .media__link {
      margin-top: 30px;

      @media (max-width: $tab-mobile) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__overview-header {
    @include text-style(0, $white, left, $bold-font-weight, 0);

    @include viewport-is(tab-mobile) {
      @include text-style(0, $white, left, $bold-font-weight, 0, 20px);
    }
  }

  &__overview-description {
    border-top: 1px solid $white;
    line-height: 1.5;

    span {
      display: inline !important; //overriding inline helper css
    }

    @include text-style(1px, $white, left, $normal-font-weight);

    @include viewport-is(tab-mobile) {
      @include text-style(1px, $white, left, $normal-font-weight, 20px, 18px);
    }

    &-links {
      @include text-style(1px, $white, left, $normal-font-weight, 20px, 20px);
      line-height: 1.5;
      margin-top: 10px;
    }

    &:nth-of-type(-n + 2) {
      padding-top: 20px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        line-height: 2;
      }
    }
  }
}
