.pricing-details {
  margin: 50px 0;

  .justify-center.grid [class*='col-'] {
    text-align: center;
    text-align-last: center;
  }
  &__header {
    font-size: 32px;
    font-weight: $bold-font-weight;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
  }

  &__content {
    text-align: center;
  }

  &__content-title {
    font-size: 24px;
  }

  &__content-text {
    font-size: 16px;
    font-weight: $normal-font-weight;
  }
}
