.tutorials {
  line-height: 1.3;
  margin-bottom: 30px;

  iframe {
    border-radius: 4px;
    height: inherit;
    width: inherit;
  }

  &__time {
    margin-top: 10px;

    @media (max-width: $tab-mobile) {
      display: flex;
      justify-content: center;
    }

    &-active {
      .emphasis-tag {
        background-color: $secondary-color;
        border-radius: 3px;
        color: $white;
      }
    }
  }

  &__image {
    &-wrapper {
      border-radius: 4px;
      height: 100%;
      width: 100%;
    }

    &-thumb {
      position: relative;

      &:hover {
        .tutorials__image-hover {
          opacity: 1;
        }
      }

      img {
        border-radius: 4px;
        display: block;
        height: 100%;
        width: 100%;
      }
    }

    &-hover {
      align-items: center;
      background-color: $off-tar;
      display: flex;
      height: 100%;
      justify-content: center;
      opacity: 0;
      position: absolute;
      top: 0;
      transition: opacity 0.3s ease-in 0.1s;
      width: 100%;

      &--content {
        padding: 25px;

        img {
          cursor: pointer;
          height: 48px;
          width: 68px;
        }
      }
    }
  }

  &__tabs {
    color: $primary-text;

    &-wrapper {
      margin-top: 50px;
    }

    .rtabs__showmore-list .rtabs__tab {
      white-space: normal;
    }

    .rtabs__tab {
      &:first-of-type {
        padding-left: 15px;
      }
      &--min-width {
        min-width: 13rem;
      }
    }

    .rtabs__tabs--vertical {
      margin-right: 30px;
    }
  }

  &__container {
    overflow-x: hidden;
  }

  &__bg {
    background: $sky-blue;

    @include viewport-is(tab-mobile) {
      background: $white;
    }
  }

  &__search {
    width: 40%;

    @include viewport-is(desktop-tab) {
      width: 60%;
    }

    @include viewport-is(tab-mobile) {
      width: 70%;
    }

    @include viewport-is(mobile) {
      position: relative;
      right: 0;
      width: 100%;
    }

    .input {
      display: flex;
      justify-content: flex-end;

      &__field-holder {
        width: 320px;
      }

      &__post-icon {
        height: auto;
        right: 0;
        width: auto;
      }
    }

    .icon-button__icon {
      font-size: 18px;
    }

    @include viewport-is(mobile) {
      width: 100%;
    }
  }

  &__videos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    margin-top: 10px;

    &-active {
      align-items: center;
      background: $exotic-blue;
      border-radius: 4px;
      display: flex;
      justify-content: center;
    }

    &-title {
      width: 75%;

      @include viewport-is(desktop-tab) {
        width: 35%;
      }

      @include viewport-is(tab-mobile) {
        width: 100%;
      }

      @include viewport-is(mobile) {
        margin-top: 20px;
        width: 100%;
      }

      &-animate {
        width: 75%;

        @include viewport-is(large-desktop) {
          width: 100%;
        }

        @include viewport-is(desktop-tab) {
          width: 100%;
        }

        @include viewport-is(tab-mobile) {
          width: 100%;
        }

        @include viewport-is(mobile) {
          width: 100%;
        }

        @include viewport-is(small-mobile) {
          width: 100%;
        }
      }
    }

    &-player {
      margin-right: 20px;
      transition: all 0.5s linear;
      width: 20%;

      @include viewport-is(desktop-tab) {
        width: 60%;
      }

      @include viewport-is(tab-mobile) {
        margin-bottom: 20px;
        width: 100%;
      }

      @include viewport-is(mobile) {
        width: 100%;
      }

      &-animate {
        height: 400px;
        margin: 20px 0;
        width: 75%;

        @include viewport-is(large-desktop) {
          width: 100%;
        }

        @include viewport-is(desktop-tab) {
          width: 100%;
        }

        @include viewport-is(tab-mobile) {
          width: 100%;
        }

        @include viewport-is(mobile) {
          height: 320px;
          width: 100%;
        }

        @include viewport-is(small-mobile) {
          height: 320px;
          width: 100%;
        }
      }
    }

    @include viewport-is(mobile) {
      margin-bottom: 0;
      margin-top: 0;
    }

    &-link {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0);
      margin-bottom: 5px;
    }

    &-description {
      @include text-style(1px, $primary-text, left, $normal-font-weight);
      margin-top: 10px;
      word-spacing: 1px;
    }
  }

  &__header {
    @include text-style(0, $primary-text, left, $boldest-font-weight, 0);
  }

  &__sub-header {
    @include text-style(0, $primary-text, left, $normal-font-weight, 0);
    margin-bottom: 20px;
  }

  &__button {
    background: $unavoidable-almond;
    border-radius: 4px;
    color: $white;
    font-size: 16px;
    font-weight: $bolder-font-weight;
    padding: 7px 20px;

    &:hover {
      background: rgba($unavoidable-almond, 0.9);
    }
  }

  &__big-header {
    @include text-style(0, $primary-text, left, $boldest-font-weight, 0);
    margin-bottom: 20px;
  }

  &__wrapper {
    align-items: center;
    color: $primary-text;
    display: flex;
    justify-content: space-between;

    @include viewport-is(tab-mobile) {
      display: block;
    }
  }

  &__subs-button {
    @media (max-width: $tab-mobile) {
      display: flex;
      justify-content: center;
    }
  }
}
