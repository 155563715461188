.subscription-mode-switch {
  align-items: center;
  display: flex;

  &__toggle {
    margin-right: 12px;
  }

  &__helper-text {
    font-size: 14px;
    line-height: 1.5;

    strong {
      font-weight: $bold-font-weight;
    }
  }

  &--flipped-horizontaly {
    .rcl-popover{
      &__parent {
        margin: 0 0 0 12px;
        order: 2;

        .subscription-mode-switch {
          &__toggle {
            margin: 6px 0;
          }
        }
      }
    }

    .subscription-mode-switch {
      &__toggle {
        margin: 0 0 0 12px;
        order: 2;
      }

      &__helper-text {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        text-align: right;

        strong {
          font-weight: $bold-font-weight;
        }
      }
    }
  }
}