.hero {
  &-content {
    align-items: center;
    background-color: $secondary-accent;
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    transition: all 1s ease-in-out;
    width: 100%;

    @include viewport-is(mobile) {
      display: none;
    }

    &__logo {
      height: 50%;
    }

    &__slogan {
      color: $white;
      font-size: 60px;
      font-weight: $boldest-font-weight;
      z-index: 1;

      @include viewport-is(tab-mobile) {
        font-size: 30px;
      }
    }

    &__background-logo {
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
      filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
      height: 200%;
      opacity: 0.1;
      position: absolute;
      right: 0;
    }
  }

  &-container {
    position: relative;
    min-height: 100vh;
  }

  &-tab {
    background: $secondary-accent;
    height: 280px;
    margin-top: 20px;
    overflow: hidden;
    position: relative;

    @include viewport-is(tab-mobile) {
      height: 240px;
    }

    @include viewport-is(mobile) {
      height: 40px;
      margin-top: 0;
    }
  }

  &-selector {
    align-items: center;
    justify-content: center;
    left: 50%;
    margin-bottom: 5px;
    position: absolute;
    top: 280px;
    transform: translate(-50%, -110%);
    -webkit-font-smoothing: subpixel-antialiased;
    white-space: nowrap;

    @include viewport-is(tab-mobile) {
      display: flex;
      flex-flow: row nowrap;
      transform: translate(-50%, -250%);
      -webkit-font-smoothing: subpixel-antialiased;
    }

    @include viewport-is(mobile) {
      top: 0;
      transform: translate(-52%, 22%);
      -webkit-font-smoothing: subpixel-antialiased;
    }

    &__tab {
      background: transparent;
      border: none;
      color: $white;
      cursor: pointer;
      font-size: 14px;
      margin-left: 20px;
      outline: none;
      padding: 6px 4px;

      @include viewport-is(mobile) {
        margin-left: 5px;
      }

      &:hover {
        border-bottom: 1px solid $white;
      }

      &--active {
        border-bottom: 1px solid $white;
      }
    }
  }

  &-body {
    overflow-x: hidden;
    position: relative;

    hr {
      background-color: $overheated-tar;
      border: 0;
      height: 1px;
    }

    @include viewport-is(mobile) {
      .container-full {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &__content {
      display: none;
      transition: all 500ms ease-out;

      &--active {
        display: block;
      }
    }
  }
}
