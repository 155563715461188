.sign-up {
  background-color: $secondary-accent;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  overflow-y: auto;

  &__container {
    align-items: center;
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__form-container {
    width: 300px;

    &--fixed-width {
      width: 500px;
    }
  }

  &__icon {
    align-items: center;
    display: flex;
    justify-content: center;

    @include viewport-is(tab-mobile) {
      display: none;
    }
  }

  &__form {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__field {
    font-size: 14px;
    margin-bottom: 24px;

    .input__label,
    .phone-number__label,
    .select-box__label {
      color: $white;
      font-size: 14px;
      font-weight: $normal-font-weight;
    }
  }

  &__field-hint {
    color: $white;
    font-size: 14px;
    margin-bottom: 8px;
    opacity: 0.8;
  }

  &__actions {
    button + button {
      margin-left: 8px;
    }
  }

  &__resend-code-button {
    background: transparent;
    border: 0;
    color: $white;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.5em;
    text-decoration: underline;
    white-space: nowrap;

    &:disabled {
      cursor: default;
      font-weight: normal;
      text-decoration: none;
    }
  }

  &__checkbox {
    margin-bottom: 8px;

    .checkbox__check {
      border: 2px solid $white;
    }

    .checkbox__input {
      &:checked {
        background: $lush-sky;
      }
    }

    .checkbox__label {
      color: $white;
      font-size: 14px;
    }

    a {
      color: $white;
      text-decoration: underline;
    }

    &__legal-papers {
      width: 235px;

      span {
        margin-right: 3px;
      }
    }
  }

  &__alert {
    margin-top: 8px;

    .alert__content-text {
      color: $white;
      font-weight: $normal-font-weight;
    }
  }

  &__sign-in {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 8px;
  }

  &__sign-in-hint {
    color: $white;
    font-size: 12px;
    margin-right: 8px;
    text-align: center;
  }

  &__sign-in-button {
    background: $multilateral-sapphire;
    border-radius: 3px;
    color: $white;
    font-size: 12px;
    padding: 6px 12px;

    &:hover {
      background: darken($multilateral-sapphire, 5%);
      border-radius: 3px;
      color: $white;
      padding: 6px 12px;
    }

    &:active {
      background: darken($multilateral-sapphire, 10%);
      border-radius: 3px;
      color: $white;
      padding: 6px 12px;
    }
  }

  &__pending-hint {
    color: $white;
    font-size: 14px;
  }

  &__pending-header {
    display: none;

    @include viewport-is(tab-mobile) {
      display: block;
    }
  }

  &__error-text {
    color: $red;
    margin-top: 8px;
  }

  &__error-hint {
    background: $warning;
    border-radius: 3px;
    color: $warning-text;
    margin-bottom: 30px;
    padding: 16px;

    &-header {
      font-weight: $bold-font-weight;
    }
  }
}
