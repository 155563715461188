.sign-up-header {
  color: $white;
  margin-bottom: 24px;

  &__text {
    font-size: 22px;
  }

  &__step {
    opacity: 0.80;
  }
}
