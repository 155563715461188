.empty-table {
  align-items: center;
  background-color: $white;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba($tertiary-accent, 0.16);
  display: flex;
  flex-flow: column wrap;
  height: 40vh;
  justify-content: center;
  line-height: $base-line-height;
  margin-top: 5px;
  width: 100%;

  &__icon {
    margin-bottom: 10px;
  }

  &__text {
    color: $secondary-text;
    font-size: 14px;
    font-weight: $bolder-font-weight;
  }

  &--with-full-height {
    height: 100vh;
  }
}
