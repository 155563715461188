.filter {
  .popover {
    &__link {
      font-weight: $normal-font-weight;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--bottom-left {
      right: 0;
      top: 40px;
      transform: translate(0, 0);
    }
  }

  &__options {
    padding: 0;
    padding-inline-start: 0;
    margin: 0;
    text-align: left;

    &-option {
      padding: 10px;

      .checkbox {
        cursor: pointer;
      }

      &:hover {
        background: $cool-gray-96;
      }
    }
  }
}
