.contact-us {
  @include viewport-is(mobile) {
    display: block;
  }

  &__modal {
    .rcl-modal__container {
      width: 304px;
    }
  }

  &__sign-in {
    margin-top: 12px;
    text-decoration: underline;
  }

  &__action {
    display: flex;

    .button {
      margin-right: 8px;
    }
  }

  &__header {
    &--alert {
      margin-bottom: 12px;
    }

    .input {
      &__label {
        margin-top: 20px;
      }
    }
  }

  &__footer {
    .rcl-modal__footer {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  #g-recaptcha {
    margin-top: 16px;
  }

  &__wrapper {
    align-items: center;
  }
}
