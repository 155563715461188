@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

@mixin generate-grid-with-gap($gap) {
  $space-needed: calc($gap / 2);

  margin: -$space-needed;

  > [class*="col-"] {
    padding: $space-needed;
  }

  > .col-bleed {
    padding: 0;
  }

  > .col-bleed-y {
    padding: 0 $space-needed;
  }

  > .col-bleed-x {
    padding: $space-needed 0;
  }
}

body {
  font-family: 'Roboto', sans-serif;
}

html {
  font-family: 'Roboto';
}

.content {
  margin-top: $navbar-min-height;
}

.content-private {
  margin-top: $navbar-min-height;
  margin-left: 65px;

  .button {
    margin-right: 8px;
  }

  .support-left-panel {
    &__header {
      .button {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;
  }
}

// Helper classes for grid with different gutter/gap
.grid {
  &.grid-gap-20 {
    @include generate-grid-with-gap(20px);
  }
}
