.sidebar {
  padding-top: 22px;
  z-index: 20;

  &:hover {
    .sidenav__branding {
      margin-left: 0px;
      transition: margin-left 1ms linear 230ms;
    }
  }

  @include viewport-is(tab-mobile) {
    display: none;
  }
}

.side-nav {
  &__brand {
    &-icon,
    &-logo {
      margin: 10px 0;
    }

    &-img {
      width: 31px;
      margin-left: 4px;
    }

    &-title-img {
      width: 198px;
      height: 30px;
    }
  }

  &__list {
    margin-top: 15px;

    &-item {
      @include target-ie {
        margin: 14px -55px;
        padding-right: 30px;
      }

      &-badge {
        border-radius: 50%;

        @include target-ie {
          top: 10px;
        }
      }
    }
  }
}
