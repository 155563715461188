body {
  margin: 0;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.img-responsive {
  height: auto;
  max-width: 100%;
}

.rtabs__panel {
  p {
    text-align: justify;
  }
}

.icon-button {
  &--tiny {
    height: 2rem;
    width: 2rem;
  }

  &__icon {
    font-size: 1.5rem;
  }
}

// hiding tab selector for platform and pricing page
// TODO: remove after API is added in affiliates 2.1
// Refer to AF-344 and AF-346

[class*='public-static_pages-features_applications'] {
  .hero-selector {
    display: none;

    @include viewport-is(mobile) {
      display: flex;
    }
  }
}

.public {
  &-static_pages {
    &-pricing_application_pricing {
      .hero-selector {
        display: none;

        @include viewport-is(mobile) {
          display: flex;
        }
      }
    }

    &-company_logos {
      .hero-body__content--active {
        margin-top: 0;
      }
    }

    &-resources_documentation {
      .hero-body__content--active {
        line-height: 1.3;
        margin: 100px 0;
      }
    }
  }
}

// rcl overrides to remove white extra background in the DatePicker in Support Page
// TODO: remove after this change is done in the rcl
.support_tickets-index {
  .DateRangePicker_picker {
    background-color: transparent;
    z-index: 5;
  }
}

.radio--small {
  font-size: 1rem;
}

// remove after RCL upgrade
.base-modal {
  &__container {
    max-height: 95%;

    @include viewport-is(mobile) {
      max-width: 95%;
    }
  }

  &__content {
    max-height: 100%;

    @include viewport-is(mobile) {
      padding: 16px;
    }
  }

  &__header {
    &-close {
      svg {
        float: right;
      }
    }
  }
}

// TODO: remove after upgrading RCL
.pill-tabs {
  .rtabs {
    &__tab:first-of-type {
      padding-left: 12px;
    }
  }
}
