.pricing-table {
  background: $white;
  display: none;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 0;
  text-align: left;

  &__border {
    display: none;

    @include viewport-is-greater(tab-mobile) {
      display: block;
    }
  }

  @include viewport-is-greater(tab-mobile) {
    display: block;
  }

  &__table {
    width: 100%;

    tbody {
      tr {
        &:not(:last-child) {
          @include shadow($overheated-tar, 0, 0.5px, 0, 0);
        }
      }
    }
  }

  &__header {
    color: $primary-text;
    font-size: 32px;
    font-weight: $bold-font-weight;
    margin-bottom: 30px;

    &-feature {
      font-size: 18px;
      font-weight: $bold-font-weight;
      padding: 10px 10px 10px 0;
    }

    &-plan {
      font-size: 20px;
      padding: 10px;
      text-align: center;
    }
  }

  &__data {
    color: $primary-text;
    font-size: 14px;
    font-weight: $bolder-font-weight;
    width: 30%;
    padding: 10px 10px 10px 0;
  }

  &__plan {
    text-align: center;
    width: 10%;
  }
}
