.workspace-tag {
  align-items: center;
  background-color: $analytical-sapphire;
  border-radius: 4px;
  color: $white;
  display: flex;
  font-size: 12px;
  justify-content: center;
  min-height: 28px;
  margin-right: 8px; 
  min-width: 28px;

  &--unfocused-cerulean {
    background-color: $unfocused-cerulean;
  }

  &--analytical-sapphire {
    background-color: $analytical-sapphire;
  }

  &--implicit-steel {
    background-color: $implicit-steel;
  }

  &--undercover-sky {
    background-color: $undercover-sky;
  }

  &--credible-dove {
    background-color: $credible-dove;
  }

  &--bleary-dove {
    background-color: $bleary-dove;
  }

  &--processed-steel {
    background-color: $processed-steel;
  }

  &--trifling-orchid {
    background-color: $trifling-orchid;
  }
}
