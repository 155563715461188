.glossary {
  line-height: 1.3;

  section {
    padding: 30px 0;
  }

  &__search-box {
    .input {
      .icon-button {
        height: auto;
        margin: 0;
        padding: 0;
        width: auto;

        &__icon {
          font-size: 18px;
        }
      }

      &__field-holder {
        width: 320px;
      }

      &__post-icon {
        img {
          height: 20px;
        }
      }
    }
  }

  &__description {
    &-header {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header {
      @include text-style(1px, $primary-text, left, $normal-font-weight, 0);
    }
  }

  &__item-navigators {
    margin-top: 20px;
    min-height: 60px;

    @include viewport-is(tab-mobile) {
      @include align-text(center);
    }

    &-link {
      @include text-style(
        0,
        rgba($primary-text, 0.75),
        left,
        $bold-font-weight,
        0,
        48px
      );
      cursor: pointer;
      display: inline-block;
      line-height: 1;
      margin-right: 20px;
      margin-top: 10px;
      outline: none;
      transform: scale(1);
      transition: transform, color, 0.15s linear;

      &:hover {
        color: $primary-text;
        transform: scale(1.2);
      }
    }
  }

  &__contents {
    min-height: 600px;

    &-group {
      line-height: 1;

      p {
        margin-block-end: 0;
        margin-block-start: 0;
      }

      &-header {
        @include text-style(
          0,
          $primary-text,
          left,
          $bold-font-weight,
          20px,
          48px
        );
      }

      &-term {
        margin-bottom: 40px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-title {
          @include text-style(0, $primary-text, left, $bold-font-weight, 10px);
          text-transform: capitalize;
        }

        &-definition {
          @include text-style(
            0.5,
            $primary-text,
            left,
            $normal-font-weight,
            0,
            18px
          );
          line-height: 1.3;
        }

        &-title,
        &-definition {
          @include viewport-is(tab-mobile) {
            @include align-text(left);
          }
        }
      }

      &-back-to-top-button {
        @include text-style(0, $primary-text, left, $bold-font-weight, 0, 18px);
        cursor: pointer;
        display: inline-block;
        margin-right: auto;
        margin-top: 40px;
        outline: none;
        text-decoration: underline;
      }

      &-header,
      &-back-to-top-button {
        @include viewport-is(tab-mobile) {
          @include align-text(left);
        }
      }
    }

    &--loading,
    &--unavailable {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 20px);
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .icon {
        font-size: 95px;
        margin-bottom: 20px;
      }
    }
  }

  &__contact {
    &-header {
      @include text-style(0, $white, left, $bold-font-weight, 0, 48px);
    }

    &-sub-header {
      @include text-style(1px, $white, left, $normal-font-weight, 25px);
    }

    .base-modal__wrapper {
      @include viewport-is(tab-mobile) {
        @include align-text(center);
      }
    }

    .base-modal__header-close {
      display: inline-flex;
      justify-content: flex-end;
    }
  }
}
