.timeline {
  &__data {
    display: flex;

    &-time,
    &-details {
      @include text-style(1px, $white, left, $normal-font-weight, 0);
      padding-bottom: 15px;

      @include viewport-is(tab-mobile) {
        @include align-text(left);
      }
    }

    &-time {
      font-weight: $bold-font-weight;
    }

    &-line {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 30px -7px;
      padding: 7px 0 0;
      position: relative;

      &-dot {
        background-color: $white;
        border-radius: 50%;
        height: 12px;
        width: 12px;
      }

      &-dash {
        background-color: transparent;
        border-left: 2px dashed $white;
        bottom: 0;
        flex: auto;
        left: calc(50% + 2px);
        position: relative;
        top: 0;
        transform: translateX(calc(-50% - 2px));
        width: 0;
      }
    }

    &:last-of-type {
      .timeline__data-line {
        margin-bottom: 0;

        &-dash {
          border: 0;
        }
      }
    }
  }
}
