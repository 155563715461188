.feature-wrapper {
  line-height: 1.3;
  padding: 60px 0;

  img {
    max-width: 100%;
  }

  .input__field-holder {
    width: 320px;
  }

  &__spinner {
    display: flex;
    justify-content: center;
    margin-top: 20vh;
  }

  &__content {
    display: flex;

    &-side-panel {
      border-radius: 5px;
      box-shadow: 0 0 6px rgba($tertiary-accent, 0.3);

      .accordion-side-panel {
        border-radius: 5px;
        border-right: 0;
        min-width: 100%;
        width: 100%;
      }
    }

    &-description {
      align-self: flex-start;
      background-color: $background;
      border-radius: 5px;
      box-shadow: 0 0 6px rgba($tertiary-accent, 0.3);
      display: flex;
      line-height: 1.5;
      min-height: 40vh;
      padding: 35px;
      text-align: justify;

      &-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        line-height: 1.5;
        width: 100%;

        &-title {
          display: flex;
          font-size: 24px;
          line-height: 1.3;
          margin-bottom: 10px;

          > .icon {
            align-items: flex-start;
            font-size: 30px;
            margin-right: 15px;

            svg {
              fill: $primary-text;
            }
          }
        }
      }

      &-spinner {
        margin: auto;
      }

      &-empty-text {
        margin: auto;
      }

      &-action {
        align-items: center;
        border-top: 1px solid rgba($tertiary-accent, 0.16);
        display: flex;
        margin-top: auto;
        padding: 30px 0 0;

        &-field {
          margin-left: 20px;
        }
      }

      ul {
        list-style: disc;
      }
    }
  }

  &-description-drawer {
    bottom: 0;
    display: none;
    left: 100%;
    position: fixed;
    top: 0;
    transition: left 0.25s ease-in-out;
    width: 100%;
    z-index: 4;

    @include viewport-is(tab-mobile) {
      display: block;
    }

    &,
    * {
      box-sizing: border-box;
    }

    .feature-wrapper__content-description {
      align-content: flex-start;
      flex-wrap: wrap;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 100px;
      padding-top: 100px;

      &-container {
        margin: 20px 0 0;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    &--visible {
      left: 0;
    }
  }
}
