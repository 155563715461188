.billing {
  &-plans {
    .pricing-table {
      padding: 20px;
    }

    .pricing-table__border {
      display: none;
    }

    &__heading {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 0;

      &-title {
        color: $primary-text;
        font-size: 24px;
        font-weight: $bold-font-weight;
        margin: 20px 0;
      }


    }

    &__features {
      background: $lighter-sky-blue;
      border-bottom: 1px solid $lighter-outdoor-tar;
      border-top: 1px solid $lighter-outdoor-tar;
      display: flex;
      font-size: 14px;
      justify-content: space-between;
      padding: 5px 0;

      &-access {
        color: $outdoor-tar;
        font-size: 12px;
        font-weight: $boldest-font-weight;
      }
    }

    &__error-modal {
      .action-with-confirmation__actions {
        button:first-of-type {
          display: none;
        }
      }
    }

    &__plans {
      &-wrapper {
        align-items: stretch;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        > div {
          margin-bottom: 12px;
        }

        .card__header--title {
          text-transform: capitalize;
        }

        .base-modal__header-text {
          white-space: nowrap;
        }
      }

      &-card {
        box-shadow: 0 0 6px $tertiary-accent-light,
          0 0 6px $tertiary-accent-light;
        border: 0;
        flex: 0 0 15%;
        height: 100%;
        margin: 0 10px 20px;
        max-width: 300px;
        padding: 20px 0;
        position: relative;

        @include target-ie {
          flex: auto;
        }

        &-content {
          &--disabled {
            opacity: 0.4;
          }
        }

        &--current {
          box-shadow: 0 19px 38px $tertiary-accent-light,
            0 15px 12px $tertiary-accent-light;
        }

        @include viewport-is(desktop-tab) {
          flex: 1 0 25%;
          margin: 0 20px 30px;
        }

        @include viewport-is(tab-mobile) {
          flex: 1 0 25%;
          margin: 0 20px 30px;
        }

        @include viewport-is(mobile) {
          flex: 1 0 50%;
          margin: 10px;
        }

        &-title {
          font-size: 36px;
          font-weight: $bold-font-weight;
          text-align: center;
          padding: 30px 0;

          sup,
          sub {
            font-size: 16px;
            font-weight: $normal-font-weight;
            padding: 0 3px;
          }

          sup {
            font-weight: $bold-font-weight;
          }
        }

        &-features {
          display: flex;
          font-size: 14px;
          justify-content: space-between;
          padding: 5px 0;
          text-transform: capitalize;

          span:first-of-type {
            white-space: nowrap;
          }

          span {
            padding: 10px 20px;

            @include viewport-is(mobile) {
              padding: 10px;
            }

            span {
              padding: 0;
            }
          }
        }

        &-actions {
          margin: 30px auto 10px;
          text-align: center;
          white-space: nowrap;

          .button {
            padding: 3px 15px;
          }

          &-button {
            background: none;
            border: none;
            bottom: 0;
            color: $primary-text;
            cursor: pointer;
            font-size: 14px;
            font-weight: $boldest-font-weight;
            margin: auto 0 5px 0;
            padding: 0.5rem 2rem;
            text-decoration: none;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}
