.platform-plans {
  align-items: stretch;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 50px 0 100px;

  &__plan-card {
    border: 1px solid rgba($tertiary-accent, 0.16);
  }

  &__section {
    line-height: 1.5;

    .support-section__actions {
      @media (max-width: $tab-mobile) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__faq-section {
    .accordion__item {
      line-height: 1.5;
    }
  }

  @include viewport-is(tab-mobile) {
    margin: 10px 0;
  }

  &__wrapper {
    color: $primary-text;

    .toggle {
      &__wrapper {
        align-items: center;
        align-self: center;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__payment-section {
    color: $primary-text;
    margin: 100px 0;
  }

  &__header,
  &__sub-header {
    align-items: stretch;
    color: $primary-text;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }

  &__header {
    @include text-style(0, $primary-text, left, $bold-font-weight, 0, 48px);

    @include viewport-is(desktop-tab) {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 40px);
      padding: 10px;
    }

    @include viewport-is(tab-mobile) {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 35px);
      padding: 10px;
    }

    @include viewport-is(tab-mobile) {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 30px);
      padding: 10px;
    }
  }

  &__sub-header {
    @include text-style(0, $primary-text, left, $bold-font-weight, 0);

    @include viewport-is(tab-mobile) {
      font-size: 24px;
    }
  }

  &__section {
    color: $primary-text;

    &-header {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0, 48px);
      margin-bottom: 10px;

      @include viewport-is(tab-mobile) {
        @include text-style(0, $primary-text, left, $bold-font-weight, 0, 35px);
        padding: 10px;
      }

      &-sub {
        @include text-style(0, $primary-text, left, $bold-font-weight, 0);

        @include viewport-is(tab-mobile) {
          @include text-style(
            0,
            $primary-text,
            left,
            $bold-font-weight,
            0,
            20px
          );
        }
      }
    }

    &-list {
      @include text-style(1px, $primary-text, left, $normal-font-weight);
      padding: 0;

      @include viewport-is(tab-mobile) {
        @include text-style(
          1px,
          $primary-text,
          left,
          $normal-font-weight,
          20px,
          20px
        );
        margin-bottom: 0;
      }

      li {
        line-height: 2;
      }
    }

    &-description {
      @include text-style(1px, $primary-text, left, $normal-font-weight);

      @include viewport-is(tab-mobile) {
        @include text-style(
          1px,
          $primary-text,
          left,
          $normal-font-weight,
          20px,
          20px
        );
      }
    }
  }

  &__advantage-section {
    background-image: url('../../../assets/images/aircraft-wing.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    position: relative;

    .platform-plans__section-list {
      border-top: 1px solid $white;
      padding-top: 20px;

      li {
        line-height: 2;
      }
    }

    .platform-plans__section-header {
      color: $white;
      margin-bottom: 20px;
    }

    .platform-plans__section-header-sub {
      color: $white;
      font-size: 24px;
      padding-bottom: 5px;
    }

    .platform-plans__section-list {
      color: $white;
      margin-top: 0;
    }

    &-bg {
      background-color: rgba($secondary-accent, 0.75);
      padding: 100px 0;

      @include viewport-is(mobile) {
        padding: 20px 0;
      }
    }
  }

  &__subscription-section {
    background: $secondary-accent;
    color: $white;
    padding: 100px 0;

    img {
      @media (max-width: $tab-mobile) {
        display: block;
        margin: 0 auto;
      }
    }

    @include viewport-is(mobile) {
      padding: 20px 0;
    }

    .platform-plans__section-header {
      color: $white;
      margin-bottom: 50px;

      @include viewport-is(mobile) {
        margin-bottom: 20px;
      }
    }

    .platform-plans__subscription-section-header {
      color: $white;
      font-size: 24px;
      margin-bottom: 10px;
    }

    .platform-plans__section-description {
      color: $white;
    }

    &-header {
      @include text-style(0, $primary-text, left, $bold-font-weight, 0);
    }
  }

  &__faq-section {
    color: $primary-text;
    padding: 100px 0 0;

    @include viewport-is(tab-mobile) {
      padding: 20px 0;
    }

    .platform-plans__section-header {
      margin-bottom: 50px;
      text-align: center;

      @include viewport-is(tab-mobile) {
        margin-bottom: 20px;
      }
    }
  }

  &__card {
    @include shadow($overheated-tar, 0, 0, 6px, 6px);
    border: 0;
    flex: 0 0 15%;
    margin: 0 10px 20px;
    max-width: 300px;
    padding: 20px 0;

    @include viewport-is(desktop-tab) {
      flex: 1 0 25%;
      margin: 0 20px 30px;
    }

    @include viewport-is(tab-mobile) {
      flex: 1 0 25%;
      margin: 0 20px 30px;
    }

    @include viewport-is(mobile) {
      flex: 1 0 50%;
      margin: 10px;
    }
  }

  &__title {
    font-size: 36px;
    font-weight: $bold-font-weight;
    text-align: center;
    padding: 30px 0;

    sup,
    sub {
      font-size: 16px;
      font-weight: $normal-font-weight;
      padding: 0 3px;
    }

    sup {
      font-weight: $bold-font-weight;
    }
  }

  &__features {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 5px 0;

    span:first-of-type {
      white-space: nowrap;
    }

    span {
      padding: 10px 20px;

      @include viewport-is(mobile) {
        padding: 10px;
      }

      span {
        padding: 0;
      }
    }
  }

  &__wrapper {
    margin: 100px 0;

    @include viewport-is(tab-mobile) {
      margin-bottom: 10px;
    }
  }

  &__actions {
    margin: 30px auto 10px;
    text-align: center;
    white-space: nowrap;

    &-button {
      background: $white;
      border-radius: 3px;
      bottom: 0;
      color: $tertiary-accent;
      font-weight: $bold-font-weight;
      margin: auto 0 5px 0;
      padding: 0.5rem 2rem;
      text-decoration: none;

      &:hover {
        color: $secondary-accent;
      }
    }
  }
}
